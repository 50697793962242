import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import carApi from "api/carApi";
import moment from "moment";
import * as XLSX from "xlsx";
import TableCarList from "./TableCarList";
import { useSelector } from "react-redux";

export default function UploadContractList({ open, onClose, onSubmit }) {
  const user = useSelector((state) => state.token);
  const [form] = Form.useForm();
  const [radioSelected, setRadioSelected] = useState(1);
  const [dataSource, setDataSource] = useState([]);
  const [dataNotFound, setDataNotFound] = useState([]);
  const [success, setSuccess] = useState(false);

  const handleChangeRadio = (e) => {
    setDataSource([]);
    setRadioSelected(e.target.value);
  };

  const onImportFile = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      //   console.log(data)
      mapData(data);
    };
    reader.readAsBinaryString(file);
  };

  useEffect(() => {
    console.log("DATA SOURCE", dataSource);
  }, [dataSource]);

  useEffect(() => {
    // console.log("DATA NOT FOUND", dataNotFound);
    if (success) {
      Modal.warning({
        title: "สัญญาที่ค้นหาข้อมูลไม่พบ",
        content: <pre>{dataNotFound.join("\n")}</pre>, // จะแสดงข้อความในรูปแบบบรรทัดใหม่
        centered: true,
      });
      setSuccess(false);
    }
  }, [dataNotFound, success]);

  const mapData = (data) => {
    // กรองข้อมูลที่ตัวแรกเป็นตัวเลข
    const filterData = data.filter((item) => typeof item[0] === "number");
    console.log("FILTERDATA", filterData);

    // ใช้ Promise.all เพื่อเรียก API ทุกครั้งใน filterData พร้อมกัน
    const promises = filterData.map((item) => {
      const value = item[1]; // หรือใช้ค่าที่คุณต้องการจาก item
      const cost_price = item[10];
      return fetchCarDetails(value, cost_price); // return Promise
    });

    // รอให้ทุก Promise เสร็จสิ้น
    Promise.all(promises)
      .then(() => {
        console.log("ดึงข้อมูลเสร็จสิ้น");
        setSuccess(true);
      })
      .catch((error) => {
        console.error("เกิดข้อผิดพลาดในการดึงข้อมูล:", error);
      });
  };

  const fetchCarDetails = (value, cost_price) => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await carApi.get("/salecar/ibm/loans", {
          params: { contractNo: value, company: radioSelected },
          headers: {
            "x-access-token": user.token,
          },
        });

        if (result.data !== "") {
          setDataSource((pre) => [
            ...pre,
            { ...result.data, COST_PRICE: cost_price },
          ]); // อัพเดตข้อมูล
        } else setDataNotFound((pre) => [...pre, value]);
        resolve(result.data); // resolve เมื่อสำเร็จ
      } catch (error) {
        reject(error); // reject หากเกิดข้อผิดพลาด
      }
    });
  };

  const handleSubmit = () => {
    onSubmit(dataSource);
  };

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Modal
      title="อัพโหลดจากไฟล์ Excel"
      open={open}
      width={1200}
      closeIcon={false}
      onCancel={handleClose}
      onOk={handleSubmit}
      footer={[
        <Button key="back" onClick={handleClose}>
          ยกเลิก
        </Button>,
        <Button key="submit" type="primary" onClick={handleSubmit}>
          เพิ่มเข้าระบบ
        </Button>,
      ]}
    >
      <div>
        <Radio.Group style={{ width: "100%" }} defaultValue={1}>
          <Space direction="vertical" onChange={handleChangeRadio}>
            <Radio value={1}>บริษัท ONE Leasing</Radio>
            <Radio value={2}>บริษัท KSM</Radio>
          </Space>
        </Radio.Group>
        <Divider />
        <Space direction="vertical">
          <Typography.Text variant="subtitle2">
            อัพโหลดไฟล์ (excel)
          </Typography.Text>
          <input type="file" title="เลือกไฟล์" onChange={onImportFile} />
        </Space>
      </div>
      {dataSource?.length > 0 && (
        <>
          <Divider />
          <TableCarList data={dataSource} editMenu={false} />
        </>
      )}
    </Modal>
  );
}
