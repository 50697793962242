import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Modal,
  Radio,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { currencyFormat } from "components/utils/Utils";
import { useSelector } from "react-redux";

const { Text } = Typography;

export default function TableCarList({ data, editMenu }) {
  const user = useSelector((state) => state.token);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [radioSelected, setRadioSelected] = useState(1);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleSearch = (value) => {
    setSearchText(value); // อัปเดตข้อความค้นหา

    let filtered = [];
    if (radioSelected === 1) {
      filtered = data.filter(
        (item) => item.CONTNO.toLowerCase().includes(value.toLowerCase()) // ค้นหาแบบ case-insensitive
      );
    } else {
      filtered = data.filter((item) =>
        item.REGNO.toLowerCase().includes(value.toLowerCase())
      );
    }

    setFilteredData(filtered); // อัปเดตข้อมูลที่กรอง
  };

  const handleClickEdit = (data) => {
    // setDataSelected(data);
    // setOpenEditStudentModal(true);
    // console.log("EDIT", data);
    Modal.confirm({
      title: "เพิ่มไปยังรายการลงขายรถ",
      content: "ต้องการเพิ่ม สัญญา " + data.CONTNO + " ไปยังรายการลงขายรถ",
      centered: true,
      onOk: () => {
        navigate("/products/admin/add", {
          state: data, // ส่ง state ไปพร้อมการ navigate
        });
      },
    });
  };

  const columns = [
    {
      key: 1,
      dataIndex: "CONTNO",
      title: "เลขสัญญา",
    },
    {
      key: 2,
      title: "เลขทะเบียน",
      render: (record) => {
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Text>{record.REGNO}</Text>
            <Text>{record.DORECV}</Text>
          </div>
        );
      },
    },
    {
      key: 3,
      title: "รายละอียดรถ",
      render: (record) => {
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Text strong>
              ({moment(record.MANUYR, "YYYY").add(-543, "years").format("YYYY")}
              ) {record.TYPE}
            </Text>
            <Text>{record.MODEL}</Text>
            <Text>{record.BAAB}</Text>
          </div>
        );
      },
    },
    {
      key: 4,
      dataIndex: "COLOR",
      title: "สี",
    },

    {
      key: 5,
      dataIndex: "ENGNO",
      title: "เลขเครื่อง",
    },
    {
      key: 6,
      dataIndex: "STRNO",
      title: "เลขคัตซี",
    },
    user.id === 7 ||
    user.id === 9 ||
    user.id === 10 ||
    user.id === 63 ||
    user.id === 80
      ? {
          key: 7,
          dataIndex: "COST_PRICE",
          title: "ราคาต้นทุน",
          render: (record) => {
            return (
              <div>
                <Text strong>{currencyFormat(record)}</Text>
              </div>
            );
          },
        }
      : {},

    {
      title: "",
      // dataIndex: "edit",
      key: "edit",
      render: (recode) => {
        if (editMenu) {
          return (
            <Tooltip title="เพิ่มเข้าระบบประมูล">
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => handleClickEdit(recode)}
              />
            </Tooltip>
          );
        }
      },
    },
  ];

  const handleChangeRadio = (e) => {
    setRadioSelected(e.target.value);
    // setData(null);
  };

  return (
    <div>
      <div style={{ flexDirection: "column", display: "flex" }}>
        <h4>ค้นหาข้อมูลในตาราง</h4>
        <Radio.Group
          style={{ width: "100%", marginBottom: 16 }}
          defaultValue={1}
        >
          <Space direction="vertical" onChange={handleChangeRadio}>
            <Radio value={1}>กรองจากเลขที่สัญญา</Radio>
            <Radio value={2}>กรองจากเลขทะเบียน</Radio>
          </Space>
        </Radio.Group>
        <Input
          styles={{ width: "100%" }}
          placeholder="ป้อนข้อมูลที่ต้องการค้นหา"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)} // เรียกฟังก์ชันค้นหา
          style={{ marginBottom: 16 }}
        />
      </div>

      <Table
        dataSource={filteredData}
        columns={columns}
        size="small"
        bordered
        rowKey="CONTNO"
      />
    </div>
  );
}
