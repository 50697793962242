import {
  Button,
  Image,
  Modal,
  Typography,
  Input,
  Select,
  Space,
  InputNumber,
} from "antd";
import { currencyFormat } from "components/utils/Utils";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function BookingCarModal({
  open,
  data,
  users,
  onSubmit,
  onClose,
}) {
  const user = useSelector((state) => state.token);
  // console.log(user);
  const [product, setProduct] = useState({});
  // const [loading, setLoading] = useState(false);
  // const [disableSubmit, setDisableSubmit] = useState(false);
  const [price, setPrice] = useState(0);
  const [nextPrice, setNextPrice] = useState(0);

  useEffect(() => {
    // setLoading(false);
    console.log(data.auction_bitrate);
    const price =
      data.auction_total === null ? data.auction_price : data.auction_total;
    const nextPrice = price + data.auction_bitrate;
    console.log(price, nextPrice);

    setProduct({
      ...data,
      auction_total: price + data.auction_bitrate,
      process_by_user_id: user?.id,
    });
    setPrice(price);
    setNextPrice(nextPrice);
    return () => {
      setProduct({});
    };
  }, [data]);

  // useEffect(() => {
  //   if (product.auction_total && product.process_by_user_id) {
  //     setDisableSubmit(false);
  //     // onSubmit(product);
  //     console.log("BUTTON", product);
  //   } else {
  //     setDisableSubmit(true);
  //   }
  // }, [product]);

  //   useEffect(() => {}, [price]);
  const handleSubmit = () => {
    if (product.auction_total && product.process_by_user_id) {
      // setDisableSubmit
      onSubmit(product);
    } else {
      // setLoading(false);
      // setD(false)
    }
    //

    onClose(false);
    // console.log("Submit");
  };

  const handleCancel = () => {
    onClose(false);
  };

  const handleChange = (value) => {
    setProduct({
      ...product,
      auction_total: value,
    });
  };

  const handleChangeSelect = (e) => {
    console.log(e);
    setProduct({
      ...product,
      // auction_total: value,
      process_by_user_id: e,
    });
  };

  return (
    <Modal
      title={"รหัสสินค้า " + product.product_code}
      open={open}
      closeIcon={false}
      footer={[
        <Button key="back" onClick={handleCancel}>
          ยกเลิก
        </Button>,
        <Button
          // disabled={disableSubmit}
          key="submit"
          type="primary"
          // loading={loading}
          onClick={handleSubmit}
        >
          ประมูล
        </Button>,
      ]}
    >
      <div style={{ flexDirection: "column", display: "flex" }}>
        <div>
          <Image
            // preview={false}
            //   width={isMediumUp ? 700 : 350}
            src={product.image_path}
          />
        </div>
      </div>
      <div>
        <Typography style={{ fontWeight: "bold" }}>
          {product.year} {product.brand} {product.model}
        </Typography>
        <Typography>{product.sub_model}</Typography>
        {/* <p style={{ fontWeight: "bold", color: "red" }}>
          ราคาสูงสุด {currencyFormat(price)}
        </p> */}
        <p style={{ color: "blue" }}>
          ใส่ราคา บิตขั้นต่ำ {data.auction_bitrate || 0} บาท
        </p>
        <Space direction="vertical" style={{ width: "100%" }}>
          <InputNumber
            style={{ width: "100%" }}
            placeholder="ราคา"
            defaultValue={nextPrice}
            onChange={handleChange}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
          {user?.role === "admin" && (
            <Select
              style={{ width: "100%" }}
              onChange={handleChangeSelect}
              // size="small"
              placeholder="เลือกผู้ประมูล"
              options={users.map((item) => ({
                value: item.id,
                label:
                  item.firstname +
                  " " +
                  item.lastname +
                  " ( " +
                  item.nickname +
                  " )",
              }))}
            />
          )}
        </Space>
      </div>
    </Modal>
  );
}
