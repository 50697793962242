import React, { useState, useEffect } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Upload,
  Row,
  Col,
  Space,
  Card,
  Modal,
  Typography,
  Checkbox,
  Divider,
} from "antd";
import Resizer from "react-image-file-resizer";
import { useLocation, useNavigate } from "react-router-dom";
import { ConsoleSqlOutlined, PlusOutlined } from "@ant-design/icons";
import SelectBrand from "./SelectBrand";
import SelectModel from "./SelectModel";
import SelectSubModel from "./SelectSubModel";
import SelectGearType from "./SelectGearType";
import SelectFuelType from "./SelectFuelType";
import SelectRegistrationType from "./SelectRegistrationType";
import moment from "moment";
import carApi from "api/carApi";
import Loading from "components/Loading";
import SelectVehicleType from "./SelectVehicleType";
import SelectProductType from "./SelectProductType";

import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { addToken } from "components/store/tokenSlice";
import { currencyFormat } from "components/utils/Utils";

const { TextArea, Search } = Input;

export default function AddProducts() {
  const user = useSelector((state) => state.token);
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState(null);
  const [coverFile, setCoverFile] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [uploading, setUploading] = useState(false);
  const [uploadImageCover, setUploadImageCover] = useState(false);
  const [priceHistory, setPriceHistory] = useState({ last_price: 0 });
  const [isChassis, setIsChassis] = useState(false);
  const [roleCostPirce, setRoleCostPrice] = useState(false);

  const [productData, setProductData] = useState({
    auction: true,
    retail: false,
    // sale_type_id: 0,
    product_code: "",
    contract_no: "",
    register_no: "",
    product_type_id: 1,
    vehicle_type_id: 1,
    brand_id: 0,
    model_te_id: 0,
    sub_model_id: "",
    gear_type_id: 1,
    year: "",
    chassis_no: "",
    engin_no: "",
    miles: 0,
    cost_price: 0,
    auction_price: 0,
    retail_price: 0,
    retail_down_price: 0,
    fuel_type_id: 1,
    seats: 0,
    registration_type_id: 0,
    spare_key: 0,
    main_warranty: 0,
    color: "",
    registration_data: "",
    service_book_center: 0,
    tax_expiration_date: "",
    image_path: "",
    mark: "",
  });

  const dataFromStock = location.state || null;

  useEffect(() => {
    if (
      user.id === 7 ||
      user.id === 9 ||
      user.id === 10 ||
      user.id === 63 ||
      user.id === 80
    ) {
      setRoleCostPrice(true);
    }
    // console.log("Data FromStock", dataFromStock);
    // console.log("USER", user);
    if (dataFromStock) {
      form.setFieldsValue({
        contract_no: dataFromStock?.CONTNO,
        register_no: dataFromStock?.REGNO + " " + dataFromStock?.DORECV,
        year: moment(dataFromStock?.MANUYR, "YYYY")
          .add(-543, "years")
          .format("YYYY"),
        chassis_no: dataFromStock?.STRNO,
        color: dataFromStock?.COLOR,
        engine_no: dataFromStock?.ENGNO,
        cost_price: dataFromStock?.COST_PRICE,
      });
      setProductData({
        ...productData,
        contract_no: dataFromStock?.CONTNO,
        register_no: dataFromStock?.REGNO + " " + dataFromStock?.DORECV,
        year: moment(dataFromStock?.MANUYR, "YYYY")
          .add(-543, "years")
          .format("YYYY"),
        chassis_no: dataFromStock?.STRNO,
        color: dataFromStock?.COLOR,
        engine_no: dataFromStock?.ENGNO,
        cost_price: dataFromStock?.COST_PRICE,
      });
    }
  }, [dataFromStock]);

  useEffect(() => {
    // console.log("Product Data", productData);
  }, [productData]);

  const handleUploadAllImage = ({ product_code }) => {
    // console.log("ALL IMAGE", fileList);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });
    setLoading(true);
    // You can use any AJAX library you like
    carApi
      .post("/salecar_files/upload/" + product_code, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        // console.log(res.json());
        setFileList([]);
      })
      .catch((err) => {
        Modal.error({
          title: "ผิดพลาด",
          content: err.message,
          centered: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUploadCover = ({ product_code }) => {
    console.log(coverFile);
    const formData = new FormData();
    formData.append("file", coverFile);
    setLoading(true);
    carApi
      .post("/salecar_files/upload/" + product_code + "/cover", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setUploadImageCover(true);

        setProductData({ ...productData, image_path: res.data });
        Modal.success({ content: "อัพโหลดรูปสำเร็จ", centered: true });
      })
      .catch((err) => {
        Modal.error({
          title: "ผิดพลาด",
          content: err.message,
          centered: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const insertProduct = async () => {
    await carApi
      .post("/salecar/admin/products", productData, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        console.log(response.status);
        setLoading(false);
        // setFileList([]);
        // setCoverFile({});
        form.resetFields();
        navigate("/products/admin/list");
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  useEffect(() => {
    // console.log("PRODUCT", productData);
    if (productData.product_code !== "") {
      if (coverFile) {
        handleUploadCover({ product_code: productData.product_code });
      } else {
        insertProduct();
      }
      if (fileList) {
        handleUploadAllImage({ product_code: productData.product_code });
      }
    }
  }, [productData.product_code]);

  useEffect(() => {
    if (productData.image_path !== "" && uploadImageCover) {
      console.log("Before Insert", productData);
      insertProduct();

      setUploadImageCover(false);
    }
  }, [productData.image_path, uploadImageCover]);

  const handleChangeSelect = (e) => {
    // console.log(e.value);
    setProductData({ ...productData, [e.name]: e.value });
  };

  const handleChangeInput = (e) => {
    form.getFieldValue("sale_type_id");
    setProductData({ ...productData, [e.target.name]: e.target.value });
  };

  const handleChangeRadio = (e) => {
    // console.log("change", e.target.name);
    if (e.target.name === "sale_type_id") {
      // setType(e.target.value);
    }
    setProductData({ ...productData, [e.target.name]: e.target.value });
  };

  const handleChangeCheckBox = (checkedValues) => {
    // console.log("checked = ", checkedValues);
    if (checkedValues[0] === 1 && checkedValues[1] === 2)
      setProductData({ ...productData, auction: true, retail: true });
    else if (checkedValues[0] === 1)
      setProductData({ ...productData, auction: true, retail: false });
    else if (checkedValues[0] === 2)
      setProductData({ ...productData, auction: false, retail: true });
    else setProductData({ ...productData, auction: false, retail: false });
  };

  const handleChangeDate = (e) => {
    setProductData({ ...productData, [e.name]: e.value });
  };

  const handleSubmit = () => {
    const product_code = moment().format("YYYYMMDDhhmmss");
    // console.log("Values", Values);
    console.log("Product", productData);
    setProductData({ ...productData, product_code: product_code });
  };

  const handleClearForm = () => {
    console.log("ClearForm");
    form.resetFields();
  };

  const onSearchChassisNo = (e) => {
    checkChassisNo(e);
  };

  const checkChassisNo = async (e) => {
    // console.log("CheckChassisNo", e);
    const result = await carApi.get("/salecar/chassis-number/" + e);
    // console.log(result.data);
    if (result.data) {
      setIsChassis(true);
    } else {
      setIsChassis(false);
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1024,
        768,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  let arr = [];
  const propsMultifile = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const image = await resizeFile(file);
      // console.log("FILE", image);
      arr.push(image);
      setFileList([...fileList, ...arr]);
      return false;
    },
    // fileList,
  };

  const propsSinglefile = {
    beforeUpload: async (file) => {
      const image = await resizeFile(file);
      setCoverFile(image);
      // setCoverFile(file);
      return false;
    },
    // fileList,
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const options = [
    {
      label: "รถประมูล",
      value: 1,
    },
    {
      label: "ขายปลีก",
      value: 2,
    },
  ];

  const CustomTextField = ({ text }) => {
    return (
      <div
        style={{
          border: "1px solid rgba(128,128,128,0.3)",
          borderRadius: "6px",
          padding: 4,
        }}
      >
        <Typography style={{ marginLeft: 4, color: "gray" }}>{text}</Typography>
      </div>
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <h3>ลงขายรถยนต์</h3>
      <Card>
        <Form
          form={form}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 18,
          }}
          layout="horizontal"
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="sale_type_id"
            label="ประเภทการขาย"
            initialValue={[1]}
            rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
          >
            {/* <Radio.Group name="sale_type_id" onChange={handleChangeRadio}>
              <Space>
                <Radio value={1}>ประมูลรถ</Radio>
                <Radio value={2}>ขายปลีก</Radio>
              </Space>
            </Radio.Group> */}
            <Checkbox.Group
              options={options}
              // defaultValue={1}
              onChange={handleChangeCheckBox}
            />
          </Form.Item>
          {(productData.auction || productData.retail) && (
            <>
              <Row gutter={24}>
                <Col span={isMediumUp ? 12 : 24}>
                  <Form.Item
                    name="contract_no"
                    label="เลขสัญญาเดิม"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <Input name="contract_no" onChange={handleChangeInput} />
                  </Form.Item>
                  <Form.Item
                    name="register_no"
                    label="เลขทะเบียน"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <Input name="register_no" onChange={handleChangeInput} />
                  </Form.Item>

                  {dataFromStock && (
                    <Form.Item name="product_type_id" label="กรอกด้านล่าง">
                      <CustomTextField
                        text={`${dataFromStock?.TYPE} ${dataFromStock?.MODEL} ${dataFromStock?.BAAB}`}
                      />
                    </Form.Item>
                  )}

                  <Form.Item name="product_type_id" label="ประเภทรถ">
                    <SelectProductType onChange={handleChangeSelect} />
                  </Form.Item>
                  <Form.Item
                    name="vehicle_type_id"
                    label="ชนิดรถ"
                    rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <SelectVehicleType onChange={handleChangeSelect} />
                  </Form.Item>

                  <Form.Item
                    name="brand_id"
                    label="ยี่ห้อรถ"
                    rules={
                      productData.product_type_id !== 4 &&
                      productData.vehicle_type_id !== 4 &&
                      productData.vehicle_type_id !== 8
                        ? [
                            {
                              required: true,
                              message: "ต้องไม่เว้นว่าง!",
                            },
                          ]
                        : []
                    }
                  >
                    <SelectBrand
                      typeId={productData.vehicle_type_id}
                      onChange={handleChangeSelect}
                    />
                  </Form.Item>
                  <Form.Item
                    name="model_te_id"
                    label="รุ่นรถ"
                    rules={
                      productData.product_type_id !== 4 &&
                      productData.vehicle_type_id !== 4 &&
                      productData.vehicle_type_id !== 8
                        ? [
                            {
                              required: true,
                              message: "ต้องไม่เว้นว่าง!",
                            },
                          ]
                        : []
                    }
                  >
                    <SelectModel
                      brandId={productData.brand_id}
                      onChange={handleChangeSelect}
                    />
                  </Form.Item>
                  <Form.Item
                    name="sub_model_id"
                    label="รุ่นย่อย"
                    rules={
                      productData.product_type_id === 1 &&
                      productData.product_type_id !== 4 &&
                      productData.vehicle_type_id !== 4 &&
                      productData.vehicle_type_id !== 8
                        ? [{ required: true, message: "ต้องไม่เว้นว่าง!" }]
                        : []
                    }
                  >
                    <SelectSubModel
                      typeId={productData.vehicle_type_id}
                      modelId={productData.model_te_id}
                      lastPrice={setPriceHistory}
                      onChange={handleChangeSelect}
                    />
                  </Form.Item>

                  <Form.Item
                    name="year"
                    label="ปีรถ"
                    rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <Input name="year" onChange={handleChangeInput} />
                  </Form.Item>
                  <Form.Item
                    name="chassis_no"
                    label="เลขคัตซี"
                    rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                    extra={
                      isChassis ? (
                        <p style={{ color: "red" }}>
                          เลขคัตซีนี้เคยถูกลงขายแล้ว
                        </p>
                      ) : (
                        <p style={{ color: "green" }}>ยังไม่มีข้อมูลเลขคัตซี</p>
                      )
                    }
                  >
                    <Search
                      name="chassis_no"
                      onChange={handleChangeInput}
                      allowClear
                      onSearch={onSearchChassisNo}
                    />
                  </Form.Item>
                  <Form.Item
                    name="engine_no"
                    label="เลขเครื่อง"
                    rules={
                      productData.vehicle_type_id !== 8
                        ? [
                            {
                              required: true,
                              message: "ต้องไม่เว้นว่าง!",
                            },
                          ]
                        : []
                    }
                  >
                    <Input name="engine_no" onChange={handleChangeInput} />
                  </Form.Item>
                  <Form.Item
                    name="color"
                    label="สี"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <Input name="color" onChange={handleChangeInput} />
                  </Form.Item>
                </Col>
                <Col span={isMediumUp ? 12 : 24}>
                  <Form.Item
                    name="miles"
                    label="เลขไมล์ (กิโลเมตร)"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <Input name="miles" onChange={handleChangeInput} />
                  </Form.Item>
                  <Form.Item
                    name="gear_type_id"
                    label="ชนิดเกียร์"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <SelectGearType onChange={handleChangeSelect} />
                  </Form.Item>
                  <Form.Item
                    name="fuel_type_id"
                    label="เชื้อเพลิง"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <SelectFuelType onChange={handleChangeSelect} />
                  </Form.Item>
                  <Form.Item
                    name="seats"
                    label="จำนวนที่นั่ง"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <Input name="seats" onChange={handleChangeInput} />
                  </Form.Item>
                  <Form.Item
                    name="registration_type_id"
                    label="ประเภทรถจดทะเบียน"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <SelectRegistrationType onChange={handleChangeSelect} />
                  </Form.Item>
                  {productData.auction && (
                    <>
                      {roleCostPirce && (
                        <Form.Item
                          name="cost_price"
                          label="ราคาต้นทุน"
                          // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                        >
                          <Input
                            name="cost_price"
                            onChange={handleChangeInput}
                          />
                        </Form.Item>
                      )}

                      <Form.Item
                        name="auction_price"
                        label={<h3 style={{ color: "red" }}>ราคาเปิดประมูล</h3>}
                        // rules={
                        //   [
                        //     { required: true, message: "ต้องไม่เว้นว่าง!" },
                        //   ]
                        // }
                      >
                        <Input
                          name="auction_price"
                          onChange={handleChangeInput}
                        />
                      </Form.Item>
                      <Form.Item
                        name="last_price"
                        label="ราคาก่อนหน้า"
                        // initialValue={priceHistory?.last_price}
                        // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                      >
                        <Typography>
                          {currencyFormat(priceHistory?.last_price)}
                        </Typography>
                      </Form.Item>
                    </>
                  )}
                  {productData.retail && (
                    <>
                      <Form.Item
                        name="retail_price"
                        label={<h3 style={{ color: "red" }}>ราคาขายปลีก</h3>}
                        rules={[
                          { required: true, message: "ต้องไม่เว้นว่าง!" },
                        ]}
                      >
                        <Input
                          name="retail_price"
                          onChange={handleChangeInput}
                        />
                      </Form.Item>
                      <Form.Item
                        name="retail_down_price"
                        label={<h3 style={{ color: "red" }}>เงินดาวน์</h3>}
                        rules={[
                          { required: true, message: "ต้องไม่เว้นว่าง!" },
                        ]}
                      >
                        <Input
                          name="retail_down_price"
                          onChange={handleChangeInput}
                        />
                      </Form.Item>
                      <Form.Item
                        name="promotion"
                        label={<h4 style={{ color: "green" }}>โปรโมชั่น</h4>}
                        // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                      >
                        <TextArea
                          name="promotion"
                          rows={4}
                          onChange={handleChangeInput}
                        />
                      </Form.Item>
                    </>
                  )}

                  <Form.Item
                    name="mark"
                    label="หมายเหตุ"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <TextArea
                      name="mark"
                      rows={4}
                      onChange={handleChangeInput}
                    />
                  </Form.Item>
                </Col>
                <Divider />
                <Col span={isMediumUp ? 12 : 24}>
                  <Form.Item
                    name="registration_date"
                    label="วันที่จดทะเบียน"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <DatePicker
                      name="registration_date"
                      onChange={(date, dateString) =>
                        handleChangeDate({
                          name: "registration_date",
                          value: dateString,
                        })
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    name="spare_key"
                    label="กุญแจสำรอง"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <Radio.Group name="spare_key" onChange={handleChangeRadio}>
                      <Radio value={1}>มี</Radio>
                      <Radio value={0}>ไม่มี</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="main_warranty"
                    label="การรับประกันหลัก"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <Radio.Group
                      name="main_warranty"
                      onChange={handleChangeRadio}
                    >
                      <Radio value={1}>มี</Radio>
                      <Radio value={0}>ไม่มี</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="service_book_center"
                    label="สมุดคู่มือการเข้าศูนย์บริการ"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <Radio.Group
                      name="service_book_center"
                      onChange={handleChangeRadio}
                    >
                      <Radio value={1}>มี</Radio>
                      <Radio value={0}>ไม่มี</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    name="tax_expiration_date"
                    label="วันหมดอายุภาษี"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <DatePicker
                      name="tax_expiration_date"
                      onChange={(date, dateString) =>
                        handleChangeDate({
                          name: "tax_expiration_date",
                          value: dateString,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={isMediumUp ? 12 : 24}>
                  <Form.Item
                    name="image_path"
                    label="รูปหน้าปก"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                  >
                    <Upload
                      maxCount={1}
                      listType="picture-card"
                      {...propsSinglefile}
                    >
                      <div>
                        <PlusOutlined />
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          อัพโหลด
                        </div>
                      </div>
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    label="รูปภาพทั้งหมด"
                    name="all_image"
                    // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
                    // valuePropName="fileList"
                    // getValueFromEvent={normFile}
                  >
                    <Upload
                      {...propsMultifile}
                      // directory
                      //  action="/upload.do"
                      listType="picture-card"
                      multiple
                    >
                      <div>
                        <PlusOutlined />
                        <div
                          style={{
                            marginTop: 8,
                          }}
                        >
                          อัพโหลด
                        </div>
                      </div>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col
                  span={24}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <Space direction="vertical">
                    <Form.Item name="submit">
                      <Button
                        style={{ width: isMediumUp ? 300 : 200 }}
                        type="primary"
                        htmlType="submit"
                        // onClick={handleSubmit}
                      >
                        บันทึกไปยังรายการสินค้า
                      </Button>
                    </Form.Item>
                    <Form.Item name="reset">
                      <Button
                        style={{ width: isMediumUp ? 300 : 200 }}
                        // type="primary"
                        onClick={handleClearForm}
                      >
                        ล้างฟอร์มทั้งหมด
                      </Button>
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Card>
    </div>
  );
}
