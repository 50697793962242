import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { Card, DatePicker, Divider, Space, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { currencyFormatTH } from "components/utils/Utils";
import ExportExcel from "./ExportExcel";
import moment from "moment";

const { Text } = Typography;

export default function ReportSales({ data, title }) {
  const [auctionList, setAuctionList] = useState([]);
  const [retailList, setRetailList] = useState([]);
  const [totalPrice, setTotalPrice] = useState({
    retail: 0,
    auction: 0,
  });
  // const [dateSelected, setDateSelected] = useState(moment().format("YYYY-MM"));

  useEffect(() => {
    // console.log("DATA", data);
    if (data.length > 0) {
      // setMonthly(data.length - 1);
      const retail = data.filter((item) => item.total_retail_price !== "0");
      const auction = data.filter((item) => item.total_auction_total !== "0");
      const totalRetail = retail.reduce(
        (sum, item) => sum + parseInt(item.total_retail_price),
        0
      );
      const totalAuction = auction.reduce(
        (sum, item) => sum + parseInt(item.total_auction_total),
        0
      );

      // const now = new Date();
      // const currentMonth = now.getMonth(); // เดือนปัจจุบัน (0-11)
      // const currentYear = now.getFullYear(); // ปีปัจจุบัน

      // const filteredData = data.filter((item) => {
      //   const date = new Date(item.last_sold_out_date);
      //   return (
      //     date.getMonth() === currentMonth && date.getFullYear() === currentYear
      //   );
      // });
      // const totalMonth = filteredData.reduce(
      //   (sum, item) => sum + parseInt(item.total_auction_total),
      //   0
      // );

      // console.log("Month: ", filteredData);

      setRetailList(retail);
      setAuctionList(auction);
      setTotalPrice({
        retail: totalRetail,
        auction: totalAuction,
        // monthly: totalMonth,
      });

      // setMonthlyList(filteredData);
    }
  }, [data]);

  const renderItem = (item) => {
    const userName = item.car_tent_name
      ? item.car_tent_name
      : item.firstname
      ? item.firstname + " " + item.lastname + " (" + item.nickname + ")"
      : "ไม่พบชื่อผู้ใช้งาน";

    return (
      <div key={item.id}>
        {item.status_type_id === 3 ? (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link to={"/users/" + item.id}>{userName}</Link>

            <Text>{currencyFormatTH(item.total_auction_total)}</Text>
          </div>
        ) : (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link to={"/users/" + item.id}>
              {item.firstname} {item.lastname} ({item.nickname})
            </Link>
            <Text>{currencyFormatTH(item.total_retail_price)}</Text>
          </div>
        )}
      </div>
    );
  };
  return (
    <div style={{ width: "100%" }}>
      <ExportExcel title={title} auction={auctionList} retail={retailList} />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card
              title={`รายละเอียดแผนภูมิ (${title.dateStart} ถึง ${title.dateEnd})`}
            >
              {auctionList.map((item) => renderItem(item))}
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // alignItems: "flex-end",
                }}
              >
                <Text strong>รวม </Text>
                <Text strong> {currencyFormatTH(totalPrice.auction)}</Text>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card title="ยอดขายปลีก">
              {retailList.map((item) => renderItem(item))}
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // alignItems: "flex-end",
                }}
              >
                <Text strong>รวม </Text>
                <Text strong> {currencyFormatTH(totalPrice.retail)}</Text>
              </div>
            </Card>
          </Grid>
          {/* <Divider /> */}

          {/* <Grid item xs={12} md={6}> */}
          {/* <Card title="ยอดประจำเดือน">
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // alignItems: "flex-end",
          }}
        >
          <Text strong>รวม </Text>
          <Text strong> {currencyFormatTH(totalPrice.monthly)}</Text>
        </div>
      </Card> */}
          {/* </Grid> */}
        </Grid>
      </Box>
    </div>
  );
}
