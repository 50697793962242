import React, { useEffect, useState } from "react";
import {
  Card,
  DatePicker,
  Divider,
  Modal,
  Radio,
  Space,
  Tabs,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import EChart from "./EChart";
import carApi from "api/carApi";
import ReportSales from "./ReportSales";
import ExportExcel from "./ExportExcel";
import ReportMonth from "./ReportMonth";
import ReportAll from "./ReportAll";
import SoldCarState from "./SoldCarState";

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function ReportMain() {
  const user = useSelector((state) => state.token);

  const items = [
    {
      key: "1",
      label: "ภาพรวมยอดขายแต่ละเดือน",
      children: <ReportAll />,
    },
    {
      key: "2",
      label: "ตรวจสอบยอดขายรถแต่ละรุ่น",
      children: <SoldCarState />,
    },
  ];

  return (
    <div>
      <h3>สรุปรายงานการขาย</h3>
      <Divider />
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
}
