import React, { useState, useEffect } from "react";

import carApi from "api/carApi";
import {
  Button,
  DatePicker,
  Image,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  ShoppingOutlined,
  FileSyncOutlined,
  FileDoneOutlined,
  CheckOutlined,
} from "@ant-design/icons";
// import { useMediaQuery } from "@mui/material";
import { currencyFormat, currencyFormatTH } from "components/utils/Utils";
import Loading from "components/Loading";

import moment from "moment/moment";
import "moment/locale/th";
import { Link, useNavigate } from "react-router-dom";
import AuctionUserListModal from "./AuctionUserListModal";
import EditProductStatus from "./EditProductStatus";
import { useDispatch, useSelector } from "react-redux";
import { addToken } from "components/store/tokenSlice";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { offerPricePDF } from "./OfferPricePDF";
import SelectCompanyModal from "./SelectCompanyModal";
import { saleCarPDF } from "./SaleCarPDF";
import PreviewCarDetailModal from "./PreviewCarDetailModal";

const { Search } = Input;

pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew Bold.ttf",
    italics: "THSarabunNew Italic.ttf",
    bolditalics: "THSarabunNew BoldItalic.ttf",
  },
  AngsanaNew: {
    normal: "AngsanaNew.ttf",
    bold: "AngsanaNew Bold.ttf",
    italics: "AngsanaNew Italic.ttf",
    bolditalics: "AngsanaNew Bold Italic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
};

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Text } = Typography;

export default function BookProductList() {
  const user = useSelector((state) => state.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const theme = useTheme();
  // const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [dataHolder, setDataHolder] = useState();
  const [auctionUserList, setAuctionUserList] = useState([]);
  const [dataEdit, setDataEdit] = useState({});
  const [openAuctionListModal, setOpenAuctionListModal] = useState(false);
  const [openProductStatusModal, setOpenProductStatusModal] = useState(false);
  const [type, setType] = useState(1);
  const [openSelectCompanyModal, setOpenSelectCompanyModal] = useState(false);
  const [openPreviewDetailModal, setOpenPreviewDetailModal] = useState(false);
  const [printType, setPrintType] = useState(1);

  useEffect(() => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }, []);

  const columns = [
    // isMediumUp
    {
      key: 1,
      // dataIndex: "image_path",
      title: "รูปภาพ",
      render: (rec) => {
        return (
          <Space direction="vertical" align="center">
            <Image width={200} src={rec.image_path} />
            <Typography>เลขสัญญาเดิม : {rec.contract_no}</Typography>
            <Typography>{rec.register_no}</Typography>
          </Space>
        );
      },
    },
    // : {
    //     key: 0,
    //   },

    {
      key: 2,
      // dataIndex: "user_id",
      title: "รายละเอียดรถ",
      render: (rec) => {
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Link to={"/products/" + rec.id}>
              {"รหัสสินค้า : " + rec.product_code}
            </Link>
            <Typography style={{ fontWeight: "bold" }}>
              ({rec.year}) {rec.brand || rec.TYPE}
            </Typography>
            <Typography style={{ color: "gray" }}>
              {rec.model || rec.MODEL}
            </Typography>
            <Typography style={{ color: "gray" }}>
              เลขเครื่อง : {rec.chassis_no}
            </Typography>
            <Typography style={{ color: "gray" }}>
              เลขไมล์ : {currencyFormat(rec.miles)}
            </Typography>
            <Typography style={{ fontWeight: "bold" }}>
              ราคาเปิดประมูล : {currencyFormat(rec.auction_price)}
            </Typography>
          </div>
        );
      },
    },
    // {
    //   key: 3,
    //   title: "ยอดประมูล",
    //   render: (rec) => {
    //     return (
    //       <div style={{ flexDirection: "column", display: "flex" }}>
    //         {rec.auction_total !== 0 ? (
    //           <>
    //             <Typography style={{ fontWeight: "bold" }}>
    //               {"ยอดปิด : " + currencyFormat(rec.auction_total)}
    //             </Typography>
    //             <Typography style={{ color: "gray" }}>
    //               {"ประมูลล่าสุด : " +
    //                 moment(rec.auction_total_update_datetime).format("lll")}
    //             </Typography>
    //           </>
    //         ) : (
    //           <Typography style={{ fontWeight: "bold", color: "red" }}>
    //             ไม่มีการประมูล
    //           </Typography>
    //         )}
    //         <Typography style={{ color: "gray" }}>
    //           {"ปิดประมูล : " + moment(rec.auction_due_datetime).format("lll")}
    //         </Typography>
    //       </div>
    //     );
    //   },
    // },
    {
      key: 4,
      // dataIndex: "name",
      title: "ผู้ชนะประมูล",
      render: (rec) => {
        return rec.process_by_user_id ? (
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "start",
            }}
          >
            <Link to={"/users/" + rec.user_id}>
              {rec.user_car_tent_name ||
                rec.user_name +
                  " " +
                  rec.user_lastname +
                  " (" +
                  rec.user_nickname +
                  ")"}
            </Link>

            {/* <Typography>อายุ : {rec.user_age}</Typography> */}
            <Typography style={{ color: "gray" }}>
              ที่อยู่ : {rec.user_car_tent_address || rec.user_address}
            </Typography>
            <Typography style={{ color: "gray" }}>
              โทรศัพท์ : {rec.user_phone}
            </Typography>
            <Link
              style={{ marginTop: 16 }}
              onClick={() => fetchAuctionUserList(rec.id)}
            >
              ดูผู้ร่วมประมูลทั้งหมด
            </Link>
          </div>
        ) : (
          <Typography style={{ fontWeight: "bold", color: "red" }}>
            ไม่พบข้อมูลผู้ประมูล
          </Typography>
        );
      },
    },
    {
      key: 5,
      title: "สถานะขาย",
      render: (rec) => {
        let color;
        switch (rec.status_type_id) {
          case 1:
            color = "success";
            break;
          case 2:
            color = "processing";
            break;
          case 3:
            color = "error";
            break;
          case 4:
            color = "error";
            break;
          default:
            break;
        }
        return (
          <Space direction="vertical">
            <Tag color={color}>{rec.status}</Tag>
            {rec.status_type_id === 3 ||
              (rec.status_type_id === 4 && (
                <Tag color="success">
                  {currencyFormatTH(rec.sold_out_price)}
                </Tag>
              ))}
            {rec.status_type_id === 2 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <Link to={"/users/" + rec.buyer_name}>
                  ผู้ซื้อ:{" "}
                  {rec.buyer_car_tent_name ||
                    rec.buyer_firstname +
                      " " +
                      rec.buyer_lastname +
                      " (" +
                      rec.buyer_nickname +
                      ")"}
                </Link>

                {/* <Typography>อายุ : {rec.user_age}</Typography> */}
                <Typography style={{ color: "gray" }}>
                  ที่อยู่ : {rec.buyer_car_tent_address || rec.buyer_address}
                </Typography>
                <Typography style={{ color: "gray" }}>
                  โทรศัพท์ : {rec.buyer_phone}
                </Typography>
                {/* <Link
                  style={{ marginTop: 16 }}
                  onClick={() => fetchAuctionUserList(rec.id)}
                >
                  ดูผู้ร่วมประมูลทั้งหมด
                </Link> */}
              </div>
            )}
          </Space>
        );
      },
    },
    {
      key: 6,
      title: "ตัวเลือก",
      render: function (rec) {
        return (
          <Space align="center">
            {(user.id === 7 ||
              user.id === 9 ||
              user.id === 10 ||
              user.id === 11) && (
              <Tooltip title="แก้ไขสถานะสินค้า">
                <Button
                  type="primary"
                  size="large"
                  shape="circle"
                  icon={<ShoppingOutlined />}
                  onClick={() => {
                    handleClickEditStatus(rec);
                  }}
                />
              </Tooltip>
            )}
            {(user.id === 7 || user.id === 9 || user.id === 10) && (
              <>
                <Tooltip title="อนุมัติขาย">
                  <Button
                    size="large"
                    shape="circle"
                    icon={<CheckOutlined />}
                    onClick={() => handleClickApproveStatus(rec)}
                  />
                </Tooltip>
              </>
            )}
          </Space>
        );
      },
    },
    {
      key: 6,
      title: "พิมพ์ใบคำขอ",
      render: function (rec) {
        return (
          <Space align="center">
            {rec.approved_status_id === 1 ? (
              <>
                <Tooltip title="พิมพ์ ใบจอง">
                  <Button
                    size="large"
                    shape="circle"
                    icon={<FileSyncOutlined />}
                    onClick={() => handleClickPrintOfferPrice(rec)}
                  />
                </Tooltip>
                <Tooltip title="พิมพ์ ใบซื้อ">
                  <Button
                    size="large"
                    shape="circle"
                    icon={<FileDoneOutlined />}
                    onClick={() => handleClickPrintSaleCar(rec)}
                  />
                </Tooltip>
              </>
            ) : (
              <>
                <Text strong>รอเฮียหรือซ้ออนุมัติ</Text>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  const fetchAuctionUserList = async (id) => {
    setLoading(true);
    await carApi
      .get(`/salecar/admin/auction-log/product/${id}`, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        // console.log(response.data);
        setAuctionUserList(response.data);
        setOpenAuctionListModal(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  useEffect(() => {
    fetchAllProduct();
  }, []);

  const fetchAllProduct = async () => {
    setLoading(true);
    await carApi
      .get("/salecar/admin/auctions-completed", {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((res) => {
        const data = res.data;
        // console.log("Products", data);
        setData(data);
        setDataHolder(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const fetchByRegisterNumber = async (value) => {
    let typeUrl = "";
    if (type === 1) {
      typeUrl = "register";
    } else {
      typeUrl = "chassis";
    }
    console.log(typeUrl);
    setLoading(true);
    await carApi
      .get("/salecar/admin/search/" + typeUrl, {
        params: { [typeUrl]: value },
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((res) => {
        const data = res.data;
        // console.log("Products", data);
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const updateProductStatus = async (data) => {
    // console.log("UPDATE STATUS", data);
    setLoading(true);
    await carApi
      .put("/salecar/admin/products/" + data.id + "/status", data, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          Modal.success({ content: "อัพเดพข้อมูลแล้ว", centered: true });
          fetchAllProduct();
        } else {
          setLoading(false);
          Modal.error({
            title: "ผิดพลาด",
            content: "อัพเดตไม่สำเร็จ กรุณาตรวจสอบข้อมูล",
            centered: true,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const updateApproveStatus = async (value) => {
    // setData(update);
    // console.log("UPDATE STATUS", data);
    setLoading(true);
    await carApi
      .put("/salecar/admin/approved/products", value, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          Modal.success({ content: "อัพเดพข้อมูลแล้ว", centered: true });
          // fetchAllProduct();
          const update = data.map((item) => {
            if (item.id === value.id) {
              return value;
            } else return item;
          });
          setData(update);
        } else {
          setLoading(false);
          Modal.error({
            title: "ผิดพลาด",
            content: "อัพเดตไม่สำเร็จ กรุณาตรวจสอบข้อมูล",
            centered: true,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const fetchAuctionListByDate = async (date) => {
    setLoading(true);
    await carApi
      .get("/salecar/admin/auctions-completed/" + date, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((res) => {
        const data = res.data;
        // console.log("Products", data);
        setData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(addToken({}));
        navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const deleteAuctionLogById = async (data) => {
    setLoading(true);
    await carApi
      .delete("/salecar/auction-log/" + data.id, {
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((res) => {
        Modal.success({
          title: "ยกเลิกรายการสำเร็จ",
          centered: true,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // dispatch(addToken({}));
        // navigate("/login");
        Modal.warning({
          title: "คำขอล้มเหลว",
          // content: "กรุณาเข้าสู่ระบบ",
          centered: true,
        });
      });
  };

  const handleCancelAuction = (data) => {
    console.log("CANCEL AUCTION", data);
    deleteAuctionLogById(data);
  };

  const handleClickEditStatus = (data) => {
    // console.log("EditData", data);
    setDataEdit(data);
    setOpenProductStatusModal(true);
  };

  const handleSubmit = (data) => {
    console.log("Submit", data);
    updateProductStatus(data);
  };

  const onChangeMonth = (date, dateString) => {
    if (dateString !== "") {
      fetchAuctionListByDate(dateString);
    }
  };

  const handleChangeSearchType = (data) => {
    // console.log("TYPE", data);
    setType(data);
  };

  const handleSearch = (value) => {
    // console.log("VALUE", value);
    // if (value) {
    //   fetchByRegisterNumber(value);
    // } else {
    //   fetchAllProduct();
    // }
    if (value) {
      let filtered = [];
      if (type === 1) {
        filtered = dataHolder.filter(
          (item) => item.register_no.toLowerCase().includes(value.toLowerCase()) // ค้นหาแบบ case-insensitive
        );
      } else {
        filtered = data.filter((item) =>
          item.chassis_no.toLowerCase().includes(value.toLowerCase())
        );
      }

      setData(filtered); // อัปเดตข้อมูลที่กรอง
    } else {
      setData(dataHolder);
    }
  };

  const handleClickApproveStatus = async (rec) => {
    setOpenPreviewDetailModal(true);
    setDataEdit(rec);
    // console.log("Approve", rec);
    // Modal.confirm({
    //   title: "ยืนยันอนุมัติขาย",
    //   closable: true,
    //   centered: true,
    //   okText: "อนุมัติ",
    //   cancelText: "ไม่อนุมัติ",
    //   onOk: () => {
    //     updateApproveStatus({ ...rec, approved_status_id: 1 });
    //   },
    //   onCancel: () => {
    //     updateApproveStatus({ ...rec, approved_status_id: 2 });
    //   },
    // });
  };

  const handleSubmitApproveStatus = async (data) => {
    updateApproveStatus(data);
  };

  const handleClickPrintOfferPrice = async (rec) => {
    setPrintType(1);
    setDataEdit(rec);
    setOpenSelectCompanyModal(true);
  };

  const handleClickPrintSaleCar = async (rec) => {
    setPrintType(2);
    setDataEdit(rec);
    setOpenSelectCompanyModal(true);
  };

  const handleSubmitSelectCompany = async (data) => {
    // console.log("PRINT TYPE", printType);
    switch (printType) {
      case 1:
        const offerPrice = await offerPricePDF(data);
        pdfMake.createPdf(offerPrice).open();
        break;
      case 2:
        const saleDoc = await saleCarPDF(data);
        pdfMake.createPdf(saleDoc).open();
        break;
      default:
        break;
    }
  };

  const handleSearchTypeStatus = (e) => {
    const term = e.target.value;
    if (term === 0) {
      setData(dataHolder);
    } else {
      const newData = dataHolder.filter((item) => {
        return item.status_type_id === term;
      });
      setData(newData);
    }
  };

  return (
    <>
      <h3>รายการปิดประมูล</h3>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Space>
          <p>ค้นหาจาก</p>
          <Select
            style={{
              width: 150,
            }}
            options={[
              { value: 1, label: <span>เลขทะเบียน</span> },
              { value: 2, label: <span>เลข Chassis</span> },
            ]}
            onChange={handleChangeSearchType}
          />
          <Search
            placeholder="กรอกเลขทะเบียนหรือเลขคัตซี"
            allowClear
            onChange={(e) => handleSearch(e.target.value)} // เรียกฟังก์ชันค้นหา
            style={{
              width: 200,
            }}
          />
        </Space>
        <Space>
          <p>กรองตามเดือน</p>
          <DatePicker
            onChange={onChangeMonth}
            picker="month"
            placeholder="เดือนที่ปิดประมูล"
            style={{ width: 200 }}
          />
        </Space>
      </div>
      <div
        style={{
          padding: 8,
          justifyContent: "right",
          display: "flex",
        }}
      >
        <Radio.Group
          defaultValue={0}
          buttonStyle="solid"
          onChange={handleSearchTypeStatus}
        >
          <Radio.Button value={0}>ทั้งหมด</Radio.Button>
          <Radio.Button value={1}>คงอยู่</Radio.Button>
          <Radio.Button value={2}>รอดำเนินการ</Radio.Button>
          <Radio.Button value={3}>ขายแล้ว</Radio.Button>
        </Radio.Group>
      </div>

      <Table dataSource={data} columns={columns} rowKey={"id"} />

      <AuctionUserListModal
        open={openAuctionListModal}
        data={auctionUserList}
        onCancelAuction={handleCancelAuction}
        onClose={setOpenAuctionListModal}
      />
      <EditProductStatus
        open={openProductStatusModal}
        data={dataEdit}
        onSubmit={handleSubmit}
        onClose={setOpenProductStatusModal}
      />
      <SelectCompanyModal
        open={openSelectCompanyModal}
        data={dataEdit}
        onClose={setOpenSelectCompanyModal}
        onSubmit={handleSubmitSelectCompany}
      />
      <PreviewCarDetailModal
        open={openPreviewDetailModal}
        onClose={setOpenPreviewDetailModal}
        data={dataEdit}
        onSubmit={handleSubmitApproveStatus}
      />
    </>

    // <PDFViewer>
    //   <MyDocument />
    // </PDFViewer>
  );
}
