import React, { useState } from "react";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import { Button, Modal } from "antd";
import moment from "moment";
import { currencyFormat } from "components/utils/Utils";
import carApi from "api/carApi";
import { useSelector } from "react-redux";

export default function AuctionByUserReportExcel({ data }) {
  const user = useSelector((state) => state.token);
  //   console.log("USER", user);
  //   const [data, setData] = useState([]);
  // let dataTable = [];

  const downloadExcel = async () => {
    // fetchAuctionSoldByUserId();
    exportToExcel();
    // Modal.confirm({
    //   title: "ดาวน์โหลด Report",
    //   content: `ดาวน์โหลดการซื้อขายของ ${data[0].user_name} ${data[0].user_lastname}`,
    //   onOk: () => {

    //   },
    // });
  };

  const exportToExcel = () => {
    const workbook = new ExcelJS.Workbook();

    const worksheet1 = workbook.addWorksheet("รายงานการซื้อขาย");

    worksheet1.columns = [
      { header: "วันที่ขาย", key: "sold_out_date", width: 20 },
      { header: "ชื่อเต๊นท์รถ", key: "user_car_tent_name", width: 20 },
      { header: "เบอร์โทรเต๊นท์รถ", key: "user_car_tent_phone", width: 15 },
      { header: "ชื่อ", key: "user_name", width: 15 },
      { header: "สกุล", key: "user_lastname", width: 15 },
      { header: "โทรศัพท์", key: "user_phone", width: 15 },
      { header: "ยี่ห้อ", key: "brand", width: 15 },
      { header: "รุ่น", key: "model", width: 15 },
      { header: "ทะเบียน", key: "register_no", width: 20 },
      {
        header: "ราคาต้นทุน",
        key: "cost_price",
        width: 20,
        style: { alignment: { horizontal: "right" } },
      },
      {
        header: "ราคาขาย",
        key: "sold_out_price",
        width: 20,
        style: { alignment: { horizontal: "right" } },
      },
      {
        header: "กำไร/ขาดทุน",
        key: "summary",
        width: 20,
        style: { alignment: { horizontal: "right" } },
      },
    ];

    worksheet1.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFCCFFCC" },
      };
      cell.font = { bold: true };
    });

    // คำนวณยอดรวม
    let totalCost = 0;
    let totalSold = 0;
    let totalProfitLoss = 0;

    data.forEach((item, index) => {
      const profitLoss = item.sold_out_price - Number(item.cost_price);

      // คำนวณยอดรวม
      totalCost += Number(item.cost_price);
      totalSold += item.sold_out_price;
      totalProfitLoss += profitLoss;

      const row = worksheet1.addRow({
        sold_out_date: moment(item.sold_out_date).format("ll"),
        user_car_tent_name: item.user_car_tent_name || "-",
        user_car_tent_phone: item.user_car_tent_phone || "-",
        user_name: item.user_name,
        user_lastname: item.user_lastname,
        user_phone: item.user_phone,
        brand: item.brand,
        model: item.model,
        register_no: item.register_no,
        cost_price: currencyFormat(item.cost_price),
        sold_out_price: currencyFormat(item.sold_out_price),
        summary: currencyFormat(profitLoss),
      });

      if (index % 2 === 0) {
        row.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFFE0B2" }, // สีส้มอ่อน
          };
        });
      }
      // Set font color for summary column
      const summaryCell = row.getCell("summary");
      summaryCell.font = {
        color: { argb: profitLoss < 0 ? "FFFF0000" : "FF006400" }, // สีแดงสำหรับค่าติดลบ, สีเขียวสำหรับค่าบวก
      };
    });

    // เพิ่มแถวสุดท้ายเป็นยอดรวม
    const totalRow = worksheet1.addRow({
      sold_out_date: "รวมทั้งหมด",
      cost_price: currencyFormat(totalCost),
      sold_out_price: currencyFormat(totalSold),
      summary: currencyFormat(totalProfitLoss),
    });

    // ตั้งค่าสไตล์ให้แถวรวม
    totalRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true };
      // cell.fill = {
      //   type: "pattern",
      //   pattern: "solid",
      //   fgColor: { argb: "FFD700" }, // สีเหลืองทอง
      // };
      cell.alignment = { horizontal: "right" };
      // if (colNumber >= 10) {
      //   // จัดชิดขวาเฉพาะคอลัมน์ตัวเลข

      // }
    });

    // เปลี่ยนสีตัวอักษรของกำไร/ขาดทุน ในแถวรวม
    const totalSummaryCell = totalRow.getCell("summary");
    totalSummaryCell.font = {
      color: { argb: totalProfitLoss < 0 ? "FFFF0000" : "FF006400" }, // สีแดงถ้าขาดทุน, สีเขียวถ้ามีกำไร
    };

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(
        blob,
        `รายวานซื้อขาย ${data[0].user_name} ${data[0].user_lastname}.xlsx`
      );
    });
  };

  return (
    <div
    //   style={{
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "flex-end",
    //     marginBottom: 24,
    //   }}
    >
      <Button type="primary" onClick={downloadExcel}>
        ดาวน์โหลด Excel
      </Button>
    </div>
  );
}
