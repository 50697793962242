import React, { useEffect, useState } from "react";
import {
  Card,
  DatePicker,
  Divider,
  Modal,
  Radio,
  Space,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import EChart from "./EChart";
import carApi from "api/carApi";
import ReportSales from "./ReportSales";
import ExportExcel from "./ExportExcel";
import ReportMonth from "./ReportMonth";

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function ReportAll() {
  const user = useSelector((state) => state.token);
  const currentDate = moment().format("YYYY-MM-DD");
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [eChart, setEchart] = useState([]);
  const [saleList, setSaleList] = useState([]);
  const [saleListMonth, setSaleListMonth] = useState([]);
  const [titleExcel, setTitleExcel] = useState("");
  const [dateSelected, setDateSelected] = useState(currentDate);
  const [carList, setCarList] = useState([]);

  useEffect(() => {
    let params = {
      dateStart: moment().add(-6, "months").format("YYYY-MM-DD"),
      dateEnd: currentDate,
    };
    setTitleExcel({
      dateStart: moment(params.dateStart).format("MM-YYYY"),
      dateEnd: moment(params.dateEnd).format("MM-YYYY"),
    });
    fetchSumPriceWeek(params);
    fetchAllSaleList(params);
    fetchDataMonth(currentDate);
    fetchCarSoldMonth(currentDate);
    setDateSelected(currentDate);
  }, []);

  const fetchSumPriceWeek = async (params) => {
    // console.log(params);
    const result = await carApi.get("/salecar/report/monthly", {
      params,
      // headers: {
      //   "x-access-token": user.token,
      // },
    });
    // console.log(result.data);
    setEchart(result.data);
  };

  const fetchAllSaleList = async (params) => {
    const result = await carApi.get("/salecar/report/sales-list", {
      params,
      headers: {
        "x-access-token": user.token,
      },
    });
    // console.log("Sales", result.data);
    setSaleList(result.data);
  };

  const fetchDataMonth = async (month) => {
    const result = await carApi.get("/salecar/report/sales-list/monthly", {
      params: { month },
      headers: {
        "x-access-token": user.token,
      },
    });
    // console.log("Sales", result.data);
    setSaleListMonth(result.data);
  };

  const fetchCarSoldMonth = async (month) => {
    const result = await carApi.get("/salecar/report/car-list/monthly", {
      params: { month },
      headers: {
        "x-access-token": user.token,
      },
    });
    // console.log("CAR LIST", result.data);
    setCarList(result.data);
  };

  const handleChangeDate = (date, dateString) => {
    console.log(date, dateString);
    if (dateString[0] !== "" && dateString[1] !== "") {
      let params = {
        dateStart: dateString[0] + "-30",
        dateEnd: dateString[1] + "-30",
      };

      setTitleExcel({
        dateStart: moment(params.dateStart).format("MM-YYYY"),
        dateEnd: moment(params.dateEnd).format("MM-YYYY"),
      });
      fetchSumPriceWeek(params);
      fetchAllSaleList(params);
    }

    // let url = "";
  };

  const onChangeMonth = (_, dateString) => {
    if (dateString !== "") {
      fetchDataMonth(dateString + "-01");
      fetchCarSoldMonth(dateString + "-01");
      setDateSelected(dateString + "-01");
    }
  };

  return (
    <div>
      {/* <h3>สรุปรายงานการขาย</h3> */}

      {/* <ExportExcel data={saleList} /> */}

      <Space direction="vertical" style={{ width: "100%" }}>
        <EChart data={eChart} />
        <Text strong>เลือกเดือนที่แสดงแผนภูมิ</Text>
        <RangePicker
          style={{ width: "100%", marginTop: "16px" }}
          picker="month"
          onChange={handleChangeDate}
        />
      </Space>
      <Divider />
      <ReportSales data={saleList} title={titleExcel} />
      <Divider />
      <h3>รายงานขายประจำเดือน</h3>
      <div
        style={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 24,
          alignItems: "center",
        }}
      >
        <Space>
          <Text>เลือกเดือน</Text>
          <DatePicker
            onChange={onChangeMonth}
            picker="month"
            style={{ width: 150 }}
          />
        </Space>
      </div>
      <ReportMonth
        data={saleListMonth}
        title={dateSelected}
        carList={carList}
      />
    </div>
  );
}
