import { Button, Divider, Modal, Table, Typography } from "antd";
import carApi from "api/carApi";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AuctionByUserReportExcel from "./AuctionByUserReportExcel";
import moment from "moment";
import { currencyFormat, currencyFormatTH } from "components/utils/Utils";

const { Text } = Typography;

export default function SelectUserListReportModal({ open, data, onClose }) {
  const user = useSelector((state) => state.token);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    // fetchUserList();
  }, []);

  // const fetchUserList = async () => {
  //   const result = await carApi.get("/salecar/admin/users/auctioneer", {
  //     headers: {
  //       "x-access-token": user.token,
  //     },
  //   });
  //   console.log("USERLIST", result.data);
  //   setDataSource(result.data);
  // };

  const handleClose = () => {
    onClose(false);
  };
  const columns = [
    {
      title: "วันที่ซื้อ",
      dataIndex: "sold_out_date",
      key: "sold_out_date",
      render: (rec) => {
        return (
          <div>
            <Text>{moment(rec).format("LL")}</Text>
          </div>
        );
      },
    },
    // {
    //   title: "รูปรถ",
    //   dataIndex: "image_path",
    //   key: "image_path",
    //   render: (rec) => {
    //     return (
    //       <div>
    //         <img src={rec} alt={""} width="150" />
    //       </div>
    //     );
    //   },
    // },

    {
      title: "รายละเอียดรถ",
      // dataIndex: "auction_count",
      key: "details",
      render: (rec) => {
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Text strong>
              ({rec.year}) {rec.brand}
            </Text>
            <Text>{rec.model || rec.MODEL}</Text>
            <Text type="secondary">{rec.register_no}</Text>
            <Text type="secondary">เลขคัตซี: {rec.chassis_no}</Text>
          </div>
        );
      },
    },
    user.id === 7 || user.id === 9 || user.id === 10
      ? {
          key: 3,
          width: 100,
          dataIndex: "cost_price",
          title: "ราคาต้นทุน",
          render: (rec) => {
            return (
              <Typography style={{ fontWeight: "bold" }}>
                {currencyFormat(rec)}
              </Typography>
            );
          },
        }
      : {},
    {
      key: 4,
      width: 100,
      dataIndex: "sold_out_price",
      title: "ราคาขาย",
      render: (rec) => {
        return (
          <Typography style={{ color: "blue", fontWeight: "bold" }}>
            {currencyFormat(rec)}
          </Typography>
        );
      },
    },
    user.id === 7 || user.id === 9 || user.id === 10
      ? {
          key: 5,
          width: 100,
          // dataIndex: "price",
          title: "กำไร/ขาดทุน",
          render: (rec) => {
            const cost_price = rec.cost_price || 0;
            const price = rec.sold_out_price || 0;
            const sum = price - cost_price;
            // let color = "green";
            console.log("SUM", sum);
            if (sum < 0) {
              return (
                <Typography style={{ color: "red", fontWeight: "bold" }}>
                  {currencyFormat(sum)}
                </Typography>
              );
            }
            return (
              <Typography style={{ color: "green", fontWeight: "bold" }}>
                +{currencyFormat(sum)}
              </Typography>
            );
          },
        }
      : {},

    // {
    //   title: "ดาวน์โหลด",
    //   //   dataIndex: "firstname",
    //   key: "name",
    //   render: (rec) => {
    //     return (
    //       <div>
    //         <AuctionByUserReportExcel data={rec} />
    //       </div>
    //     );
    //   },
    // },
  ];
  return (
    <div>
      <Modal
        title="รายละเอียด"
        width={1000}
        destroyOnClose
        open={open}
        onOk={handleClose}
        // closeIcon={false}
        onCancel={handleClose}
        // footer={
        //   [
        //     <Button key="delete" danger onClick={handleDelete}>
        //       ลบสินค้านี้
        //     </Button>,
        //     <Button
        //       key="close"
        //       // href="https://google.com"
        //       // loading={loading}
        //       onClick={handleClose}
        //     >
        //       ยกเลิก
        //     </Button>,
        //     <Button key="submit" type="primary" onClick={handleSubmit}>
        //       บันทึก
        //     </Button>,
        //   ]
        // }
      >
        <>
          <Divider />
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <h4>
              ผู้ซื้อ: {data[0]?.user_name} {data[0]?.user_lastname}
            </h4>
            <AuctionByUserReportExcel data={data} />
          </div>

          <Table dataSource={data} columns={columns} size="small" rowKey="id" />
        </>
      </Modal>
    </div>
  );
}
