import { Button, Divider, Modal, Table, Typography } from "antd";
import carApi from "api/carApi";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AuctionByUserReportExcel from "./AuctionByUserReportExcel";
import moment from "moment";
import { currencyFormat, currencyFormatTH } from "components/utils/Utils";
import CarDifferenceWithRateCarExcel from "./CarDifferenceWithRateCarExcel";

const { Text } = Typography;
export default function CarListModal({ data, open, onClose }) {
  const user = useSelector((state) => state.token);
  const [carList, setCarList] = useState([]);
  //   const [dataSource, setDataSource] = useState([]);

  const handleClose = () => {
    onClose(false);
  };

  const columns = [
    {
      title: "วันที่ขาย",
      dataIndex: "sold_out_date",
      key: "sold_out_date",
      render: (rec) => {
        return (
          <div>
            <Text>{moment(rec).format("LL")}</Text>
          </div>
        );
      },
    },
    {
      title: "รายละเอียดรถ",
      // dataIndex: "auction_count",
      key: "details",
      render: (rec) => {
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Text strong>
              ({rec.year}) {rec.brand}
            </Text>
            <Text>{rec.model || rec.MODEL}</Text>
            <Text>{rec.sub_model || rec.BAAB}</Text>
            <Text type="secondary">{rec.register_no}</Text>
            <Text type="secondary">เลขคัตซี: {rec.chassis_no}</Text>
          </div>
        );
      },
    },
    {
      title: "ผู้ซื้อ",
      // dataIndex: "auction_count",
      key: "buyer",
      render: (rec) => {
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Text strong>
              {rec?.firstname} {rec?.lastname}
            </Text>
            <Text>{rec.phone}</Text>
            {/* <Text type="secondary">{rec.register_no}</Text> */}
            {/* <Text type="secondary">เลขคัตซี: {rec.chassis_no}</Text> */}
          </div>
        );
      },
    },
    {
      key: "rate_price",
      width: 100,
      dataIndex: "rate_price",
      title: "ราคาในตารางเรทรถ",
      render: (rec) => {
        if (rec) {
          return (
            <div style={{ flexDirection: "column" }}>
              <Text strong>{currencyFormat(rec * 1000)}</Text>
            </div>
          );
        } else return "-";
      },
    },
    {
      key: "sold_out_price",
      width: 100,
      dataIndex: "sold_out_price",
      title: "ราคาขาย",
      render: (rec) => {
        return (
          <div style={{ flexDirection: "column" }}>
            <Text strong style={{ color: "blue" }}>
              {currencyFormat(rec)}
            </Text>
          </div>
        );
      },
    },
    {
      key: "difference",
      width: 100,
      // dataIndex: "sold_out_price",
      title: "ผลต่าง",
      render: (rec) => {
        if (rec.rate_price) {
          const price = rec.sold_out_price - rec.rate_price * 1000;
          let checkDifference = "";
          let color = "";
          if (price >= 0) {
            checkDifference = "+";
            color = "green";
          } else {
            checkDifference = "";
            color = "red";
          }
          return (
            <div style={{ flexDirection: "column" }}>
              <Text strong style={{ color }}>
                {checkDifference}
                {currencyFormat(price)}
              </Text>
            </div>
          );
        }
      },
    },
    // {
    //   title: "ผลต่างทั้งหมดรุ่นนี้",
    //   //   dataIndex: "firstname",
    //   key: "name",
    //   render: (rec) => {
    //     // if (rec?.rate_price) {
    //     return (
    //       // <CarDifferenceWithRateCarExcel detail={rec} />
    //       <div>
    //         {/* */}
    //         <Button
    //           type="primary"
    //           // onClick={() => handleDownloadRateCarList(rec)}
    //           onClick={() => console.log("DATA", rec)}
    //         >
    //           Excel
    //         </Button>
    //       </div>
    //     );
    //     // }
    //   },
    // },
  ];
  return (
    <div>
      <Modal
        title="รายละเอียด"
        width={1000}
        destroyOnClose
        open={open}
        onOk={handleClose}
        // closeIcon={false}
        onCancel={handleClose}
        // footer={
        //   [
        //     <Button key="delete" danger onClick={handleDelete}>
        //       ลบสินค้านี้
        //     </Button>,
        //     <Button
        //       key="close"
        //       // href="https://google.com"
        //       // loading={loading}
        //       onClick={handleClose}
        //     >
        //       ยกเลิก
        //     </Button>,
        //     <Button key="submit" type="primary" onClick={handleSubmit}>
        //       บันทึก
        //     </Button>,
        //   ]
        // }
      >
        <>
          <Divider />
          {/* <div
            style={{
              justifyContent: "space-between",
              display: "flex",
            }}
          ></div> */}

          <Table dataSource={data} columns={columns} size="small" rowKey="id" />
        </>
      </Modal>
    </div>
  );
}
