import React, { useState, useEffect, useCallback } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Typography,
  Select,
  Divider,
  Table,
} from "antd";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { debounce } from "lodash";
import { currencyFormat } from "components/utils/Utils";
import carApi from "api/carApi";
import { useLocation, useNavigate } from "react-router-dom";
import SelectModel from "components/products/add_products/SelectModel";
import SelectSubModel from "components/products/add_products/SelectSubModel";
import SelectBrand from "components/products/add_products/SelectBrand";
import { useSelector } from "react-redux";
import moment from "moment";
const { Text } = Typography;
const vehicles = [
  {
    id: 1,
    type_th: "รถยนต์",
    type_en: "Cars",
  },
  {
    id: 2,
    type_th: "รถเครื่องจักรการเกษตร",
    type_en: "Tractor",
  },
  {
    id: 3,
    type_th: "รถบรรทุก",
    type_en: "Truck",
  },
];
export default function SoldCarState() {
  const user = useSelector((state) => state.token);
  const [form] = Form.useForm();
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const [priceHistory, setPriceHistory] = useState("");
  const [carList, setCarList] = useState([]);

  const [productData, setProductData] = useState({
    vehicle_type_id: 1,
    brand_id: 0,
    model_te_id: 0,
    sub_model_id: "",
    year: "",
  });

  const handleChangeSelect = (e, v) => {
    console.log(v, e);
    setProductData({ ...productData, [e.name]: e.value });
  };

  const handleChangeInput = (e) => {
    // form.getFieldValue("sale_type_id");
    const { name, value } = e.target;
    if (e !== "") {
      updateProductData(name, value);
      // setProductData({ ...productData, [e.target.name]: e.target.value });
    }
  };

  const updateProductData = useCallback(
    debounce((name, value) => {
      setProductData((prev) => ({ ...prev, [name]: value }));
    }, 1000), // หน่วง 1.5 วินาที
    []
  );

  useEffect(() => {
    if (
      productData.vehicle_type_id &&
      productData.brand_id !== 0 &&
      productData.model_te_id !== 0 &&
      productData.year !== ""
    ) {
      handleSubmit(productData);
    }
  }, [productData]);

  const handleSubmit = async (data) => {
    // console.log("Data", data);
    // const dataTest = {
    //   vehicle_type_id: "1",
    //   brand_id: "1017",
    //   model_te_id: "252",
    //   sub_model_id: "",
    //   year: "2006",
    // };
    const result = await carApi.get(
      `/salecar/admin/sold-out-price`,
      {
        params: {
          ...data,
          //   ...dataTest,
        },
      }
      //   {
      //     headers: {
      //       "x-access-token": user.token,
      //     },
      //   }
    );
    console.log("Vehicle List", result.data);
    setCarList(result.data);
  };

  const onFinish = (values) => {
    console.log("Submitted:", values);
  };

  const columns = [
    {
      title: "วันที่ขาย",
      dataIndex: "sold_out_date",
      key: "sold_out_date",
      render: (rec) => {
        return (
          <div>
            <Text>{moment(rec).format("LL")}</Text>
          </div>
        );
      },
    },
    {
      title: "รูปรถ",
      dataIndex: "image_path",
      key: "image_path",
      render: (rec) => {
        return (
          <div>
            <img src={rec} alt={""} width="150" />
          </div>
        );
      },
    },
    {
      title: "รายละเอียดรถ",
      // dataIndex: "auction_count",
      key: "details",
      render: (rec) => {
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Text strong>
              ({rec.year}) {rec.brand}
            </Text>
            <Text>{rec.model || rec.MODEL}</Text>
            <Text type="secondary">{rec.register_no}</Text>
            <Text type="secondary">เลขคัตซี: {rec.chassis_no}</Text>
          </div>
        );
      },
    },
    {
      title: "ผู้ซื้อ",
      // dataIndex: "auction_count",
      key: "buyer",
      render: (rec) => {
        return (
          <div style={{ flexDirection: "column", display: "flex" }}>
            <Text strong>
              {rec?.firstname} {rec?.lastname}
            </Text>
            <Text>{rec.phone}</Text>
            {/* <Text type="secondary">{rec.register_no}</Text> */}
            {/* <Text type="secondary">เลขคัตซี: {rec.chassis_no}</Text> */}
          </div>
        );
      },
    },

    {
      key: 4,
      width: 100,
      dataIndex: "sold_out_price",
      title: "ราคาขาย",
      render: (rec) => {
        return (
          <div style={{ flexDirection: "column" }}>
            <Text strong>{currencyFormat(rec)}</Text>
          </div>
        );
      },
    },

    // {
    //   title: "ดาวน์โหลด",
    //   //   dataIndex: "firstname",
    //   key: "name",
    //   render: (rec) => {
    //     return (
    //       <div>
    //         <AuctionByUserReportExcel data={rec} />
    //       </div>
    //     );
    //   },
    // },
  ];

  return (
    <Form
      form={form}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 18,
      }}
      layout="horizontal"
      onFinish={onFinish}
    >
      <>
        <Row gutter={24}>
          <Col span={isMediumUp ? 12 : 24}>
            <Form.Item
              name="vehicle_type_id"
              label="ชนิดรถ"
              rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
            >
              <Select
                value={productData.vehicle_type_id}
                onChange={(e, v) => handleChangeSelect(v)}
              >
                {vehicles.map((item) => {
                  return (
                    <Select.Option
                      key={item.id}
                      value={item.id}
                      name="vehicle_type_id"
                    >
                      {item.type_th}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              name="brand_id"
              label="ยี่ห้อรถ"
              rules={[
                {
                  required: true,
                  message: "ต้องไม่เว้นว่าง!",
                },
              ]}
            >
              <SelectBrand
                typeId={productData.vehicle_type_id}
                onChange={handleChangeSelect}
              />
            </Form.Item>
            <Form.Item
              name="model_te_id"
              label="รุ่นรถ"
              rules={[
                {
                  required: true,
                  message: "ต้องไม่เว้นว่าง!",
                },
              ]}
            >
              <SelectModel
                brandId={productData.brand_id}
                onChange={handleChangeSelect}
              />
            </Form.Item>
            <Form.Item
              name="sub_model_id"
              label="รุ่นย่อย"
              // rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
            >
              <SelectSubModel
                typeId={productData.vehicle_type_id}
                modelId={productData.model_te_id}
                lastPrice={setPriceHistory}
                onChange={handleChangeSelect}
              />
            </Form.Item>

            <Form.Item
              name="year"
              label="ปีรถ"
              rules={[{ required: true, message: "ต้องไม่เว้นว่าง!" }]}
            >
              <Input name="year" onChange={handleChangeInput} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Table
          dataSource={carList}
          columns={columns}
          size="small"
          rowKey="id"
        />
      </>
    </Form>
  );
}
