import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { Card, DatePicker, Divider, Modal, Space, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { currencyFormatTH } from "components/utils/Utils";
import AuctionByUserReportExcel from "./AuctionByUserReportExcel";
import moment from "moment";
import carApi from "api/carApi";
import { useSelector } from "react-redux";
import SelectUserListReportModal from "./SelectUserListReportModal";
import CarListModal from "./CarListModal";

const { Text } = Typography;
export default function ReportMonth({ data, carList, title }) {
  const user = useSelector((state) => state.token);
  const [auctionList, setAuctionList] = useState([]);
  const [retailList, setRetailList] = useState([]);
  const [totalPrice, setTotalPrice] = useState({
    retail: 0,
    auction: 0,
  });
  const [soldUserList, setSoldUserList] = useState([]);
  const [openSelectedUserModal, setOpenSelectedUserModal] = useState(false);
  const [openCarListModal, setOpenCarListModal] = useState(false);
  // const [dateSelected, setDateSelected] = useState(moment().format("YYYY-MM"));

  useEffect(() => {
    // console.log("TITLE", title);
    if (data.length > 0) {
      // setMonthly(data.length - 1);
      const retail = data.filter((item) => item.total_retail_price !== "0");
      const auction = data.filter((item) => item.total_auction_total !== "0");
      const totalRetail = retail.reduce(
        (sum, item) => sum + parseInt(item.total_retail_price),
        0
      );
      const totalAuction = auction.reduce(
        (sum, item) => sum + parseInt(item.total_auction_total),
        0
      );

      // const now = new Date();
      // const currentMonth = now.getMonth(); // เดือนปัจจุบัน (0-11)
      // const currentYear = now.getFullYear(); // ปีปัจจุบัน

      // const filteredData = data.filter((item) => {
      //   const date = new Date(item.last_sold_out_date);
      //   return (
      //     date.getMonth() === currentMonth && date.getFullYear() === currentYear
      //   );
      // });
      // const totalMonth = filteredData.reduce(
      //   (sum, item) => sum + parseInt(item.total_auction_total),
      //   0
      // );

      // console.log("Month: ", filteredData);

      setRetailList(retail);
      setAuctionList(auction);
      setTotalPrice({
        retail: totalRetail,
        auction: totalAuction,
        // monthly: totalMonth,
      });

      // setMonthlyList(filteredData);
    }
  }, [data]);

  const fetchAuctionSoldByUserId = async (data) => {
    // console.log("Month", title);
    // setLoading(true);
    await carApi
      .get(`/salecar/admin/auction-log/sold/users/${data.id}`, {
        params: { month: title },
        headers: {
          "x-access-token": user.token,
        },
      })
      .then((response) => {
        setSoldUserList(response.data);
        setOpenSelectedUserModal(true);
        // console.log("SOLD USER LIST", response.data);
      })
      .catch((err) => {
        Modal.warning({
          title: "คำขอล้มเหลว",
          content: "Sessions Expired",
          centered: true,
        });
      });
  };

  const handleClickName = async (data) => {
    // console.log("USER", data);
    fetchAuctionSoldByUserId(data);
  };

  const handleClickCarList = async () => {
    setOpenCarListModal(true);
  };

  const renderItem = (item) => {
    const userName = item.car_tent_name
      ? item.car_tent_name
      : item.firstname
      ? item.firstname + " " + item.lastname + " (" + item.nickname + ")"
      : "ไม่พบชื่อผู้ใช้งาน";

    return (
      <div key={item.id}>
        {item.status_type_id === 3 ? (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link style={{ flex: 3 }} onClick={(e) => handleClickName(item)}>
              {userName}
            </Link>
            <Text style={{ flex: 1 }} type="secondary">
              {item.total_sales_count} คัน
            </Text>
            <Text style={{ flex: 1 }}>
              {currencyFormatTH(item.total_auction_total)}{" "}
            </Text>
          </div>
        ) : (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link onClick={(e) => handleClickName(item)}>
              {item.firstname} {item.lastname} ({item.nickname})
            </Link>
            <Text>{currencyFormatTH(item.total_retail_price)}</Text>
          </div>
        )}
      </div>
    );
  };
  return (
    <div style={{ width: "100%" }}>
      {/* <AuctionByUserReportExcel data={rec} /> */}

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card
              title={
                <Space direction="vertical" style={{ margin: 16 }}>
                  <Text strong style={{ fontSize: 16 }}>
                    ยอดประจำเดือน: {moment(title).locale("th").format("MMMM")}
                  </Text>
                  <Link style={{ fontSize: 14 }} onClick={handleClickCarList}>
                    จำนวนรถที่ขาย {carList.length} คัน
                  </Link>
                </Space>
              }
            >
              {auctionList.map((item) => renderItem(item))}
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // alignItems: "flex-end",
                }}
              >
                <Text strong>รวม </Text>
                <Text strong> {currencyFormatTH(totalPrice.auction)}</Text>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card title="ยอดขายปลีก">
              {retailList.map((item) => renderItem(item))}
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  // alignItems: "flex-end",
                }}
              >
                <Text strong>รวม </Text>
                <Text strong> {currencyFormatTH(totalPrice.retail)}</Text>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <SelectUserListReportModal
        data={soldUserList}
        open={openSelectedUserModal}
        onClose={setOpenSelectedUserModal}
      />

      <CarListModal
        data={carList}
        open={openCarListModal}
        onClose={setOpenCarListModal}
      />
    </div>
  );
}
